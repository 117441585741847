import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Checkbox from '@mui/joy/Checkbox';
import { useSelector } from 'react-redux';

// MUI Components
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { Stack } from "@mui/joy";
import { Pagination } from "@mui/material";
import { useMediaQuery } from "@mui/material";

// API
import { fetchRecipeByUserId, fetchAllRecipePictures } from '../../../../api/recipesAPI';
import { fetchDataForRecipe } from "../../../../api/stockAPI";

// Components
import MobileRecipeCard from "./MobileRecipeCard";

// Styles
import "./MobileRecipe.css";

function MobileRecipeList() {
    const { id } = useParams();
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");
    const navigate = useNavigate();

    const [recipes, setRecipes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showFilters, setShowFilters] = useState(false);
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = recipes.slice(indexOfFirstItem, indexOfLastItem);

    // Change la page actuelle
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Fetch all recipes with their associated ingredients (linkedProducts)
    const fetchRecipesWithDetails = async () => {
        try {
            const data = await fetchRecipeByUserId();
            const pictures = await fetchAllRecipePictures();

            const detailedRecipes = await Promise.all(
                data.map(async (recipe) => {
                    const picture = pictures.find(pic => pic.recipe_id === recipe.id);
                    if (picture) {
                        recipe.imageUrl = `${process.env.REACT_APP_API_URL}${picture.path}`;
                    }

                    // Fetch additional details (including ingredients) for the recipe
                    const recipeDetails = await fetchDataForRecipe(recipe.id);
                    return {
                        ...recipe,
                        linkedProducts: recipeDetails.linkedProducts,
                        allProducts: recipeDetails.allProducts,
                    };
                })
            );

            setRecipes(detailedRecipes);
        } catch (error) {
            console.error("Erreur lors de la récupération des recettes et des détails:", error);
        }
    };

    useEffect(() => {
        fetchRecipesWithDetails();
    }, []);

    // Gérer le changement des ingrédients sélectionnés
    const handleIngredientChange = (ingredientValue) => {
        setSelectedIngredients(prevSelected =>
            prevSelected.includes(ingredientValue)
                ? prevSelected.filter(id => id !== ingredientValue)
                : [...prevSelected, ingredientValue]
        );
    };

    // Filtrer les recettes par texte de recherche et par ingrédients
    const filteredRecipes = recipes.filter(recipe => {
        const searchTermLower = searchTerm.toLowerCase();

        // Filtrer selon le texte de recherche
        const matchesSearchTerm = recipe.name.toLowerCase().includes(searchTermLower) ||
            recipe.description.toLowerCase().includes(searchTermLower);

        // Filtrer les recettes qui ne contiennent pas les ingrédients sélectionnés
        const matchesSelectedIngredients = !recipe.linkedProducts?.productIds?.some(linkedProduct =>
            selectedIngredients.includes(linkedProduct.productId)
        );

        return matchesSearchTerm && matchesSelectedIngredients;
    });

    const currentRecipes = filteredRecipes.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <section className="recipe-list-mobile">
            <div className="recipe-mobile-header">
                <h2>Gestion des recettes</h2>
                <Input
                    type="text"
                    placeholder="Rechercher une recette..."
                    variant="outlined"
                    color="neutral"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ width: 330 }}
                />
                <Button variant="plain" color="success" onClick={() => setShowFilters(!showFilters)}>
                    Filtres
                </Button>

                {showFilters && (
                    <div className="filters-mobile">
                        <div className="filters-text">
                            Exclure les recettes contenant :
                        </div>
                        {recipes.length > 0 && recipes[0].allProducts
                            .slice()
                            .sort((a, b) => a.label.localeCompare(b.label))
                            .map(product => (
                                <Checkbox
                                    key={product.value}
                                    label={product.label}
                                    size="sm"
                                    checked={selectedIngredients.includes(product.value)}
                                    onChange={() => handleIngredientChange(product.value)}
                                />
                            ))}
                    </div>
                )}
            </div>

            <div className="mobile-recipe-list">
                {currentRecipes.map(recipe => (
                    <Link key={recipe.id} to={`/recipes/${recipe.id}`}>
                        <MobileRecipeCard recipe={recipe} isTablet={isTablet} />
                    </Link>
                ))}
            </div>

            <div className="pagination">
                <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '20px', marginBottom: "1rem" }}>
                    <Pagination
                        size="small"
                        siblingCount={0}
                        count={Math.ceil(filteredRecipes.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </div>
        </section>
    );
}

export default MobileRecipeList;
