import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

// API
import { createOrder } from '../../api/ordersAPI';
import { fetchCurrentUserInfos } from '../../api/userAPI';
import { createInstantPayment } from '../../api/paygreenAPI';
import { sendSummaryMail, sendGroupOrderMail } from "../../api/mailingAPI";
import { createGroupOrder } from '../../api/groupOrdersAPI';
import { createGroupParticipant } from '../../api/groupOrdersParticipantsAPI';


// Elements
import Button from '../../components/elements/button/Button';
import dayjs from 'dayjs';

import CircularProgress from '@mui/material/CircularProgress';

// Components
import OrderItem from '../../components/orders/OrderItem';
import OrderPrice from '../../components/orders/OrderPrice';
import OrderCondition from '../../components/orders/OrderCondition';
import PreorderDate from '../../components/orders/preorders/PreorderDate';
import Addresses from '../../components/orders/Addresses';
import GroupOrderConditions from '../../components/orders/GroupOrderConditions';
import TypeOfDelivery from '../../components/orders/TypeOfDelivery';

// Styles
import '../../components/orders/Order.css';
import 'reactjs-popup/dist/index.css';

function OrderValidation() {
    const navigate = useNavigate();

    // Global State
    const cartItems = useSelector(state => state.cart.items);
    const preorderState = useSelector(state => state.preorder);
    const totalTTC = useSelector(state => state.cart.totalTTC);
    const preorderEnabled = JSON.parse(localStorage.getItem('preorderEnabled'));
    console.log('preorderEnabled', preorderEnabled)
    const isInDeliveryZone = JSON.parse(sessionStorage.getItem('isInDeliveryZone'));
    const postalCode = useSelector(state => state.deliveryZone.userPostalCode);

    // Local State
    const [userName, setUserName] = useState('');
    const [userInfos, setUserInfos] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const [groupOrderConditionsChecked, setgroupOrderConditionsCheckedIsChecked] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        setSelectedAddress(preorderState?.selectedAddress);
    }, [preorderState?.selectedAddress]);

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const userInfo = await fetchCurrentUserInfos();
                setUserName(userInfo.firstname);
                setUserInfos(userInfo);
            } catch (error) {
                console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
            }
        };

        getUserInfo();
    }, [isChecked]);

    const handleContinue = () => {
        navigate('/home');
    };

    const toStripeAmount = (amount) => Math.round(amount * 100);


    const validateUserAndAddress = (user, roles, isChecked, selectedAddress, groupOrderConditionsChecked, isInDeliveryZone) => {
        if (!user) {
            toast.error('Veuillez vous connecter pour continuer');
            return false;
        }

        const isUserAuthorized = roles.includes("admin") || roles.includes("client");
        if (!isUserAuthorized) {
            toast.error('Accès refusé: vous n’avez pas les autorisations nécessaires');
            return false;
        }

        if (!isChecked) {
            toast.error('Acceptez les conditions pour continuer');
            return false;
        }

        if (preorderEnabled || !isInDeliveryZone) {
            if (!selectedAddress) {
                toast.error('Veuillez sélectionner une adresse de livraison');
                return false;
            }

            if (!isInDeliveryZone && !groupOrderConditionsChecked) {
                toast.error('Acceptez les conditions de commande groupée pour continuer');
                return false;
            }
        }

        return true;
    };


    // Build order data for order creation

    const buildOrderData = (userId, stripeAmount, cartItems, preorderState, selectedAddress) => {
        const cartitems = cartItems.map(item => ({
            recipeId: item.id,
            quantity: item.quantity
        }));

        const orderData = {
            userId: userId,
            price: stripeAmount,
            type: "pickup",
            cartitems: cartitems
        };

        //Handle the preorder case
        if (preorderEnabled && isChecked) {
            if (!preorderState.selectedDate || !preorderState.selectedTime) {
                toast.error("Veuillez sélectionner une date et une heure de livraison.");
                setLoading(false);
                return null;
            }
            const addressId = parseInt(selectedAddress, 10);
            const formattedSelectedDate = dayjs(preorderState.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD');

            if (dayjs(formattedSelectedDate).isValid()) {
                const [hours, minutes, seconds] = preorderState.selectedTime.split(':').map(Number);
                const deliveryDateTime = dayjs(formattedSelectedDate)
                    .set('hour', hours)
                    .set('minute', minutes)
                    .set('second', seconds);

                if (deliveryDateTime.isValid()) {
                    orderData.preorder = true;
                    orderData.addressId = addressId;
                    orderData.deliveryTime = deliveryDateTime.format('YYYY-MM-DD HH:mm:ss');
                } else {
                    console.error('Invalid deliveryDateTime:', deliveryDateTime);
                    toast.error("Date ou heure de livraison invalide.");
                    setLoading(false);
                    return null;
                }
            } else {
                console.error('Invalid formattedSelectedDate:', formattedSelectedDate);
                toast.error("Date de livraison sélectionnée invalide.");
                setLoading(false);
                return null;
            }
        }

        return orderData;
    };

    //Handle the case of a group order
    const handleGroupOrder = async (createdOrder, userInfos, cartItems, postalCode, selectedAddress) => {
        if (!isInDeliveryZone && groupOrderConditionsChecked && createdOrder) {
            const addressId = parseInt(selectedAddress, 10);
            const orderId = createdOrder.order.id;
            const groupOrderData = { postal_code: postalCode };

            try {
                const newGroupOrder = await createGroupOrder(groupOrderData);

                if (newGroupOrder) {
                    const groupParticipantData = {
                        address_id: addressId,
                        order_id: orderId,
                        user_id: userInfos.id,
                        group_order_id: newGroupOrder.id
                    };

                    const newParticipant = await createGroupParticipant(groupParticipantData);


                    const mailData = {
                        "name": userInfos.firstname,
                        "email": userInfos.email,
                        "order": {
                            "order_num": createdOrder.order.order_num,
                        },
                        "cartItems": cartItems,
                        "groupOrder": {
                            "postal_code": newGroupOrder.postal_code,
                            "max_participants": newGroupOrder.max_participants,
                            "current_participants": newGroupOrder.current_participants
                        }
                    };

                    await sendGroupOrderMail(mailData);
                }
            } catch (error) {
                console.error("Erreur lors de la création de la commande groupée ou du participant:", error);
                toast.error("Une erreur s'est produite lors de la création de la commande groupée. Veuillez réessayer.");
            }
        }
    };

    //Handle paiement with credit card and Stripe
    const handleCheckout = async () => {

        setLoading(true);

        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user ? user.id : null;
        const roles = JSON.parse(localStorage.getItem('roles') || "[]");
        const stripeAmount = toStripeAmount(totalTTC);

        if (!validateUserAndAddress(user, roles, isChecked, selectedAddress, groupOrderConditionsChecked, isInDeliveryZone)) {
            setLoading(false);
            return;
        }

        try {
            const orderData = buildOrderData(userId, stripeAmount, cartItems, preorderState, selectedAddress);
            if (!orderData) return;

            const createdOrder = await createOrder(orderData);
            const orderObject = createdOrder.order || createdOrder.response?.order
            console.log('createdOrder', createdOrder)
            const mailInfos = {
                name: userInfos.firstname,
                email: userInfos.email,
                cartItems: cartItems,
                order: orderObject
            }


            console.log('mailInfos', mailInfos)

            await sendSummaryMail(mailInfos)
            await handleGroupOrder(createdOrder, userInfos, cartItems, postalCode, selectedAddress);

            navigate('/checkout');
        } catch (error) {
            console.error("Error creating order:", error);
            toast.error("Une erreur s'est produite lors de la création de la commande. Veuillez réessayer.");
        } finally {
            setLoading(false);
        }
    };

    //Handle paiement with 'ticket resto' and Paygreen
    const handlePaygreenPayment = async () => {
        setLoading(true); // Ajoutez cette ligne pour définir le chargement à true au début de la fonction

        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user ? user.id : null;
        const roles = JSON.parse(localStorage.getItem('roles') || "[]");
        const stripeAmount = toStripeAmount(totalTTC);

        if (!validateUserAndAddress(user, roles, isChecked, selectedAddress, groupOrderConditionsChecked, isInDeliveryZone)) {
            setLoading(false);
            return;
        }

        try {
            const orderData = buildOrderData(userId, stripeAmount, cartItems, preorderState, selectedAddress);
            if (!orderData) return;

            const createdOrder = await createOrder(orderData);
            console.log('createdOrder', createdOrder)
            const orderNum = createdOrder.response.order.order_num.toString();
            const orderObject = createdOrder.order || createdOrder.response?.order


            const mailInfos = {
                name: userInfos.firstname,
                email: userInfos.email,
                cartItems: cartItems,
                order: createdOrder.response.order
            };

            await sendSummaryMail(mailInfos);
            await handleGroupOrder(createdOrder, userInfos, cartItems, postalCode, selectedAddress);

            const paymentResult = await createInstantPayment(stripeAmount, orderNum);
            const hostedPaymentURL = paymentResult.instantPayment.data.hosted_payment_url;
            window.location.href = hostedPaymentURL;
        } catch (error) {
            console.error("Erreur lors du traitement du paiement Paygreen :", error);
            toast.error("Une erreur s'est produite lors du traitement du paiement. Veuillez réessayer.");
        } finally {
            setLoading(false);
        }
    };

    const btnText = preorderEnabled ? "pré-commande" : "commande";

    return (
        <div className="order-validation-container">
            {loading && (
                <div className="loader-overlay">
                    <CircularProgress className="loader" color="success" />
                </div>
            )}
            <div className="order-validation-title flex-center">
                <h1>Voici votre récapitulatif {userName}</h1>
            </div>
            <div className="order-validation-action flex-center">
                <Button className="btn-border btn-sm" text={`Changer la ${btnText}`} onClick={handleContinue} />
            </div>
            <div className="order-validation-items">
                {cartItems.map(item => (
                    <OrderItem key={item.id} item={item} />
                ))}
            </div>
            <div className="order-validation-price flex-center">
                <OrderPrice />
            </div>

            {/* type of delivery components */}
            <div className="flex-center">
                <TypeOfDelivery preorderEnabled={preorderEnabled} />
            </div>
            {/* type of delivery components */}


            {preorderEnabled && (
                <div className="order-validation-preorder">
                    <div className="order-validation-date flex-center">
                        <PreorderDate />C
                    </div>
                    <div className="order-validation-addresses flex-center">
                        <Addresses />
                    </div>
                </div>
            )}
            {!isInDeliveryZone && (
                <div>
                    <div className="order-validation-addresses flex-center">
                        <Addresses postalCode={postalCode} setLoading={setLoading} />
                    </div>
                    <div className='flex-center'>
                        <GroupOrderConditions
                            groupOrderConditionsChecked={groupOrderConditionsChecked}
                            setgroupOrderConditionsCheckedIsChecked={setgroupOrderConditionsCheckedIsChecked}
                        />
                    </div>
                </div>
            )}
            <div className="order-validation-condition flex-center">
                <OrderCondition
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                />
            </div>
            <div className="order-validation-checkout flex-center">
                <Button
                    className="btn-full btn-ms"
                    text="Payer par carte bancaire"
                    onClick={handleCheckout}
                />
                <Button
                    className="btn-border btn-ms"
                    text="Payer en Ticket Resto"
                    onClick={handlePaygreenPayment}
                />
            </div>
        </div>
    );
}

export default OrderValidation;
