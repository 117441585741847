// Importation du fichier CSS pour appliquer des styles à ce composant
import "../../views/client-dashboard/ClientDashboard.css";

// Déclaration du composant ClientSubscription qui reçoit les informations utilisateur en prop
function ClientSubscription({ userInfo }) {

    // Affichage des informations utilisateur dans la console pour débogage
    console.log("userInfo", userInfo); // Affiche tout l'objet userInfo
    console.log("userId", userInfo?.id); // Affiche l'ID de l'utilisateur s'il est défini
    console.log("user mail", userInfo?.email); // Affiche l'email de l'utilisateur s'il est défini

    return (
        // La section principale contenant la présentation et les options d'abonnement
        <section className='client-subscription' 
            style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            
            {/* Bloc pour le titre de l'abonnement */}
            <div className='flex-center'>
                <h2 className="client-subscription-title">
                    Explorez de Nouvelles Saveurs en toute simplicité avec le Menu-Maker de Mamossa
                </h2>
            </div>
            
            {/* Bloc pour la description de l'abonnement et ses avantages */}
            <div className='flex-center'>
                <p className="client-subscription-description">
                    Découvrez notre catalogue de recettes et notre concepteur de Menu.
                    Calculez les coûts, apports nutritionnels des recettes, prévoyez les listes de courses de vos menus,
                    découvrez de nouvelles recettes et partagez vos créations !<br /><br />
                    Abonnez-vous à une des formules pour simplifier votre cuisine avec le Menu Maker de Mamossa. <br /><br />
                    Libérerez votre créativité culinaire dès maintenant !
                </p>
            </div>

            {/* Intégration de la table de prix Stripe pour l'abonnement */}
            <div className='stripe-pricing'>
                {/* Balise Stripe personnalisée pour afficher la table de prix */}
                <stripe-pricing-table
                    pricing-table-id="prctbl_1PGLNoLn6islyFl10iHKSc58" // Identifiant de la table de prix
                    publishable-key="pk_live_E27q31wJqPtkHNEoQzSJya8200AoUbm2JJ" // Clé publique Stripe
                    client-reference-id={userInfo ? userInfo?.id : ''} // Envoie l'ID utilisateur s'il est défini
                    customer-email={userInfo ? userInfo?.email : ''} // Envoie l'email utilisateur s'il est défini
                >
                </stripe-pricing-table>
            </div>

        </section>
    );
}

// Exportation du composant pour utilisation dans d'autres parties de l'application
export default ClientSubscription;
