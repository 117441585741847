import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Checkbox from '@mui/joy/Checkbox';
import { useSelector } from 'react-redux';

// MUI Components
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import { Stack } from "@mui/joy";
import { Pagination } from "@mui/material";
import { useMediaQuery } from "@mui/material";

// Components
import PopupConfirm from '../../elements/popup/popup-confirm/PopupConfirm';
import PopupRecipe from '../../elements/popup/popup-recipe/PopupRecipe';
import ButtonComponent from '../../elements/button/Button';

// API
import { getAllRecipes, fetchAllRecipePictures, createRecipe, uploadRecipeImage, fetchRecipeByUserId, countRecipeByUserId } from '../../../api/recipesAPI';
import { fetchDataForRecipe } from "../../../api/stockAPI";

// Components
import MobileRecipeCard from "./mobile/MobileRecipeCard";

// Toast
import toast from 'react-hot-toast';

// Styles
import "./mobile/MobileRecipe.css";

function RecipesTable() {
    const { id } = useParams();

    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");
    const isDesktop = useMediaQuery("(min-width: 1024px)");
    const navigate = useNavigate();

    const subscriptionExists = useSelector((state) => state.subscription.subscriptionExists);
    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);
    console.log('subscriptionExists', subscriptionExists)
    console.log('subscriptionInfos', subscriptionInfos)

    const [recipes, setRecipes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({
        entrées: false,
        plats: false,
        desserts: false,
        boissons: false
    });

    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = recipes.slice(indexOfFirstItem, indexOfLastItem);

    // Changez la page
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const [recipeCount, setRecipeCount] = useState(0);  // Compter les recettes de l'utilisateur

    useEffect(() => {
        const fetchRecipeCount = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const userId = user.id
                const countData = await countRecipeByUserId(userId);
                setRecipeCount(countData.recipeCount);
            } catch (error) {
                console.error("Erreur lors de la récupération du nombre de recettes :", error);
            }
        };

        if (subscriptionExists && subscriptionInfos.subscription_type === "free") {
            fetchRecipeCount();  // Appeler le comptage des recettes si l'utilisateur a un abonnement "free"
        }
    }, [subscriptionExists, subscriptionInfos]);

    // Désactiver le bouton si l'utilisateur a un abonnement "free" et possède au moins une recette
    const isButtonDisabled = subscriptionExists && subscriptionInfos.subscription_type === "free" && recipeCount > 0;



    const fetchRecipesWithPictures = async () => {
        try {
            const data = await fetchRecipeByUserId();
            const pictures = await fetchAllRecipePictures();

            const recipesWithDetails = await Promise.all(data.map(async (recipe) => {
                const picture = pictures.find(pic => pic.recipe_id === recipe.id);
                if (picture) {
                    recipe.imageUrl = `${process.env.REACT_APP_API_URL}${picture.path}`;
                }

                // Fetch additional details for the recipe
                const recipeDetails = await fetchDataForRecipe(recipe.id);


                return {
                    ...recipe,
                    linkedProducts: recipeDetails.linkedProducts,
                    allProducts: recipeDetails.allProducts,
                };
            }));

            setRecipes(recipesWithDetails);
        } catch (error) {
            console.error("Error fetching recipes with details:", error);
        }
    };

    useEffect(() => {
        fetchRecipesWithPictures();
    }, []);

    const handleFilterChange = (filterName) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: !prevFilters[filterName]
        }));
    };

    const handleIngredientChange = (ingredientValue) => {
        setSelectedIngredients(prevSelected =>
            prevSelected.includes(ingredientValue)
                ? prevSelected.filter(id => id !== ingredientValue)
                : [...prevSelected, ingredientValue]
        );
    };

    const filteredRecipes = recipes.filter(recipe => {
        const searchTermLower = searchTerm.toLowerCase();

        // Filtrer les recettes basées sur le texte de recherche et les catégories
        const matchesSearchAndCategory = (
            (recipe.name.toLowerCase().includes(searchTermLower) ||
                recipe.description.toLowerCase().includes(searchTermLower)) &&
            (filters.entrées ? recipe.partId === 1 : true) &&
            (filters.plats ? recipe.partId === 2 : true) &&
            (filters.desserts ? recipe.partId === 3 : true) &&
            (filters.boissons ? recipe.partId === 4 : true)
        );

        // Filtrer les recettes qui ne contiennent pas les ingrédients sélectionnés
        const matchesSelectedIngredients = !recipe.linkedProducts.productIds.some(linkedProduct =>
            selectedIngredients.includes(linkedProduct.productId)
        );

        return matchesSearchAndCategory && matchesSelectedIngredients;
    });

    const indexOfLastRecipe = currentPage * itemsPerPage;
    const indexOfFirstRecipe = indexOfLastRecipe - itemsPerPage;
    const currentRecipes = filteredRecipes.slice(indexOfFirstRecipe, indexOfLastRecipe);

    const handleCreateRecipe = async (formData) => {
        // Vérification des données du formulaire
        if (!formData.name || !formData.description || !formData.price || !formData.image || !formData.portions) {
            toast.error("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        console.log('formData', formData);

        try {
            // Crée la recette et récupère l'ID de la recette
            const newRecipeResponse = await createRecipe(formData);
            console.log('newRecipeResponse', newRecipeResponse);

            if (newRecipeResponse && newRecipeResponse.data && newRecipeResponse.data.id) {
                const recipeId = newRecipeResponse.data.id;  // Obtient le recipeId ici
                console.log('recipeId in RecipesTable', recipeId);

                // Upload de l'image de la recette
                await uploadRecipeImage(recipeId, formData.image);  // Envoie le recipeId ici

                toast.success('Recette créée avec succès');

                // Mets à jour la liste des recettes et ferme le popup
                fetchRecipesWithPictures();
                setIsCreatePopupOpen(false);
            } else {
                toast.error('Erreur lors de la création de la recette. Réponse inattendue de l\'API.');
            }
        } catch (error) {
            console.error("Erreur lors de la création de la recette", error);
            toast.error('Erreur lors de la création de la recette. Veuillez réessayer.');
        }
    };

    const roles = JSON.parse(localStorage.getItem('roles') || "[]");
    const isAdmin = roles.includes("admin");


    return (
        <section className="recipe-list-mobile">
            <div className="recipe-mobile-header">
                <Input
                    type="text"
                    placeholder="Rechercher une recette..."
                    variant="outlined"
                    color="neutral"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ width: 530 }}
                />
                <Button variant="plain" color="success" onClick={() => setShowFilters(!showFilters)}>Filtres</Button>
                {showFilters && (
                    <div className="filters">
                        <div className="filters-text">
                            <Typography level="body-xs">Exclure les recettes contenant :</Typography>
                        </div>
                        <div className="filters">
                            {recipes.length > 0 && recipes[0].allProducts
                                .slice() // Create a copy of the array to avoid modifying the original array
                                .sort((a, b) => a.label.localeCompare(b.label)) // Sort alphabetically by label
                                .map(product => (
                                    <Checkbox
                                        key={product.value}
                                        label={product.label}
                                        size="sm"
                                        checked={selectedIngredients.includes(product.value)}
                                        onChange={() => handleIngredientChange(product.value)}
                                    />
                                ))}
                        </div>
                    </div>
                )}
            </div>
            <div style={{ textAlign: "right", marginRight: "5rem", marginTop: "2rem" }}>
                {(isAdmin || (subscriptionExists && subscriptionInfos.subscription_type !== "free") || (subscriptionExists && subscriptionInfos.subscription_type === "free" && recipeCount === 0)) ? (
                    <PopupRecipe
                        trigger={
                            <ButtonComponent
                                className="btn-add btn-add-product-table"
                                type="button"
                                text="+ Ajouter une recette"
                                isOpen={isCreatePopupOpen}
                            />
                        }
                        onClose={() => setIsCreatePopupOpen(false)}
                        onCreate={handleCreateRecipe}
                        title="Ajouter une recette"
                        message="Message de confirmation"
                    />
                ) : (
                    <Tooltip
                        color="danger"
                        size="sm"
                        variant="outlined"
                        title="L'abonnement 'free' ne permet de créer qu'une seule recette"
                    >
                        <Button
                            className="btn-upgrade"
                            color="danger"
                            size="sm"
                            onClick={() => window.open('https://billing.stripe.com/p/login/test_3cs9EfeWEfql7ZKeUU', '_blank')}
                        >
                            Mettre à niveau l'abonnement
                        </Button>
                    </Tooltip>
                )}
            </div>

            <div className="recipe-list flex-center">
                {currentRecipes.map(recipe => (
                    <a key={recipe.id} href={`/recipes/${recipe.id}`} target="_blank" rel="noopener noreferrer">
                        <MobileRecipeCard recipe={recipe} isTablet={isTablet} isDesktop={isDesktop} />
                    </a>
                ))}
            </div>

            <div className="pagination">
                <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '20px', marginBottom: "1rem" }}>
                    <Pagination
                        size="small"
                        siblingCount={0}
                        count={Math.ceil(filteredRecipes.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </div>
        </section >
    );
}

export default RecipesTable;
