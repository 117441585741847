import * as React from 'react';

function PricingPage() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("userId", user.id)
    console.log("user mail", user.email)

    return (

        <stripe-pricing-table pricing-table-id="prctbl_1PGLNoLn6islyFl10iHKSc58"
            publishable-key="pk_live_E27q31wJqPtkHNEoQzSJya8200AoUbm2JJ"
            client-reference-id={user ? user.id : ''}
            customer-email={user ? user.email : ''}>
        </stripe-pricing-table>

    );
}

export default PricingPage;
