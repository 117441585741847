import { useState, useRef, useEffect } from "react";

//Images
import DefautProfilPicture from "../../assets/profil/profil-picture.webp";

//MUI Components
import EditIcon from '@mui/icons-material/Edit';
import Button from "../elements/button/Button";

//Imported Components
import PopupUpdate from "../elements/popup/popup-update/PopupUpdate";
import ClientAllergies from "./ClientAllergies";
import ClientImpact from "./ClientImpact";
import PopupAllergy from "../elements/popup/popup-allergy/PopupAllergy";

//API
import { uploadProfilPicture } from "../../api/userAPI";

// Toast
import toast from 'react-hot-toast';


function ClientInfos({ userInfo }) {
  //User's infos props contains all users informations

  //Local States
  const [userInfos, setUserInfos] = useState(userInfo);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [profilPicture, setProfilPicture] = useState("");
  const [isAllergyPopupOpen, setIsAllergyPopupOpen] = useState(false);


  //Formate date to DD/MM/AAAA
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  //Verify and update profil picture state
  useEffect(() => {
    if (userInfo.profilePicture) {
      setProfilPicture(userInfo.profilePicture);
    }
  }, [userInfo]);



  // Reference for the hidden input file
  const fileInputRef = useRef(null);

  //Open hidden input file  when user click
  const handleEditIconClick = () => {
    fileInputRef.current.click();
  };

  //Update userInfos state when personnal infos are updated by the ueer
  function handleUpdateConfirm(updatedData) {
    setUserInfos(updatedData);
    setOpenUpdatePopup(false);
  }


  //Handle th change of profil picture
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {

        const modifiedFileName = file.name.replace(/\s+/g, '_');


        const renamedFile = new File([file], modifiedFileName, { type: file.type });

        const userId = userInfo.id;
        const response = await uploadProfilPicture(userId, renamedFile);
        toast.success("Votre photo de profil sera mise à jour")

        const updatedUserInfo = {
          ...userInfos,
          profilePicture: `${process.env.REACT_APP_API_URL}${response.profilePictureUrl}`
        };

        setUserInfos(updatedUserInfo);
        setProfilPicture(`${process.env.REACT_APP_API_URL}${response.profilePictureUrl}`);

        console.log('Picture response', response.message);
      } catch (error) {
        console.error("Erreur lors de l'upload de la photo de profil", error);
      }
    }
  };



  ///Pass current user's infos in UpdatePopup
  const handleInfosClick = (userInfo) => {
    setSelectedUser(userInfo);
    setOpenUpdatePopup(true);
  };

  //Set fields displayed in UpdatePopup
  const profilFields = [
    { name: 'firstname', label: 'Prénom' },
    { name: 'lastname', label: 'Nom' },
    { name: 'email', label: 'Email' },
  ];

  return (
    <div className="client-infos-container container">
      <div className="picture-mobile flex-center">
        <div className="client-infos-picture-mobile">
          <img
            src={`${process.env.REACT_APP_API_URL}${userInfo.picture}` ? `${process.env.REACT_APP_API_URL}${userInfo.picture}` : DefautProfilPicture}
            alt="Client Profil"
            className="client-picture"
          />
        </div>
      </div>

      <div className="client-infos flex-center">
        <div className="client-infos-picture">
          <img
            src={`${process.env.REACT_APP_API_URL}${userInfo.picture}` ? `${process.env.REACT_APP_API_URL}${userInfo.picture}` : DefautProfilPicture}
            alt="Client Profil"
            className="client-picture"
          />
        </div>
        <div className="client-picture-actions">
          <EditIcon sx={{ color: "#fff", fontSize: "12px", cursor: "pointer" }} onClick={handleEditIconClick} />
          {/* Input file caché */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleFileChange}
          />
        </div>
      </div>

      <div className="client-infos-perso flex-center">
        <h2>Mes Informations</h2>
        <div className="client-full-name">
          {userInfos.firstname} {userInfo.lastname}
        </div>
        <div className="client-mail">{userInfo.email}</div>
        <div className="client-inscription">
          Inscrit depuis le {formatDate(userInfo.createdAt)}
        </div>
      </div>

      <div className="client-infos-allergies">
        <div className="client-allergies-title">
          <h2 className="flex-center" style={{ marginBottom: 4 }}>Mes allergies</h2>
          <div className="flex-center" >
            <PopupAllergy
              trigger={
                <Button
                  className="btn-add btn-add-product-table"
                  type="button"
                  text="Gérer mes allergies"
                  onClick={() => setIsAllergyPopupOpen(true)} // Optionnel si vous souhaitez ouvrir le popup via un bouton
                />
              }
              userId={userInfo.id} // Transmettez l'ID de l'utilisateur
              onClose={() => setIsAllergyPopupOpen(false)}
            />
          </div>


        </div>
        <ClientAllergies />

        <div className="client-infos-action flex-center">
          <Button
            className="btn-border btn-ms"
            text="Modifier mes informations"
            onClick={() => handleInfosClick(userInfo)}
          />
          {openUpdatePopup && (
            <PopupUpdate
              open={openUpdatePopup}
              onClose={() => setOpenUpdatePopup(false)}
              initialData={selectedUser}
              fields={profilFields}
              title="Modifier le profil"
              onConfirm={handleUpdateConfirm}
            />
          )}
        </div>
        <div className="flex-center">
          <ClientImpact />
        </div>

      </div>





    </div>
  );
}

export default ClientInfos;
